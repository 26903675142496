import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
// import { Disclosure } from "@headlessui/react";
// import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
// import { ChevronDownIcon } from "@heroicons/react/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import scrollTo from "utils/scrollTo";
import { useTranslation } from "react-i18next";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO,
  onClickClose,
}) => {
  const { t } = useTranslation();
  // const _renderMenuChild = (item: NavItemType) => {
  //   return (
  //     <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
  //       {item.children?.map((i, index) => (
  //         <Disclosure key={i.href + index} as="li">
  //           <NavLink
  //             exact
  //             strict
  //             to={{
  //               pathname: i.href || undefined,
  //             }}
  //             className="flex px-4 py-2.5 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-[2px]"
  //             activeClassName="text-secondary"
  //           >
  //             <span
  //               className={!i.children ? "block w-full" : ""}
  //               onClick={onClickClose}
  //             >
  //               {i.name}
  //             </span>
  //             {i.children && (
  //               <span
  //                 className="block flex-grow"
  //                 onClick={(e) => e.preventDefault()}
  //               >
  //                 <Disclosure.Button
  //                   as="span"
  //                   className="flex justify-end flex-grow"
  //                 >
  //                   <ChevronDownIcon
  //                     className="ml-2 h-4 w-4 text-neutral-500"
  //                     aria-hidden="true"
  //                   />
  //                 </Disclosure.Button>
  //               </span>
  //             )}
  //           </NavLink>
  //           {i.children && (
  //             <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
  //           )}
  //         </Disclosure>
  //       ))}
  //     </ul>
  //   );
  // };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <span
        key={item.id}
        className="dark:text-white flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
        onClick={() => scrollTo(item.href)}
        onMouseDown={onClickClose}
      >
        {item.name}
      </span>
      // <Disclosure
      //   key={item.id}
      //   as="li"
      //   className="text-neutral-900 dark:text-white"
      // >
      //   <NavLink
      //     exact
      //     strict
      //     className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
      //     to={{
      //       pathname: item.href || undefined,
      //     }}
      //     activeClassName="text-secondary"
      //   >
      //     <span
      //       className={!item.children ? "block w-full" : ""}
      //       onClick={onClickClose}
      //     >
      //       {item.name}
      //     </span>
      //     {item.children && (
      //       <span
      //         className="block flex-grow"
      //         onClick={(e) => e.preventDefault()}
      //       >
      //         <Disclosure.Button
      //           as="span"
      //           className="flex justify-end flex-grow"
      //         >
      //           <ChevronDownIcon
      //             className="ml-2 h-4 w-4 text-neutral-500"
      //             aria-hidden="true"
      //           />
      //         </Disclosure.Button>
      //       </span>
      //     )}
      //   </NavLink>
      //   {item.children && (
      //     <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
      //   )}
      // </Disclosure>
    );
  };

  return (
    <div className="overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Logo />
        <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
          <span>
            {t("menu_mobile.heading")}
          </span>

          <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
            <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {data.map(_renderItem)}
      </ul>
      <div className="flex items-center justify-between py-6 px-5 space-x-4">
        {/* <a href="/#" target="_blank" rel="noopener noreferrer"> */}
        <span
          onMouseDown={onClickClose}
          onClick={() => scrollTo("SectionContact")}
        >
          <ButtonPrimary>{t("menu_mobile.button")}</ButtonPrimary>
        </span>
        {/* </a> */}
      </div>
    </div>
  );
};

export default NavMobile;
