import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ImageSmall from "images/hero-right.collage.570x587.webp";
import ImageLarge from "images/hero-right.collage.640x659.webp";
import scrollTo from "utils/scrollTo";
import { useTranslation } from 'react-i18next';

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
            {t("hero.title")}
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            {t("hero.desc")}
          </span>
          <ButtonPrimary
            onClick={() => scrollTo("SectionBecomeAnAuthor")}
          >
            {t("hero.cta")}
          </ButtonPrimary>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={ImageSmall} alt="hero" width="343" height="353" srcSet={`${ImageSmall} 480w, ${ImageLarge} 1280w`} />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
