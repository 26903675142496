import React, { FC } from "react";
import { Helmet } from "react-helmet";

export interface PageTitleProps {
  title?: string;
}

const PageTitle: FC<PageTitleProps> = ({ title: text = "" }) => {
  return (
    <Helmet>
        <title>DLA || {`${text || 'Dubai Luxury Advisor'}`}</title>
    </Helmet>
  );
};

export default PageTitle;
