import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'shared/Select/Select';

interface Options {
  value: string;
  index: number;
  array: string[];
}

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language
  );

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    localStorage.setItem('selectedLanguage', language);
    window.location.reload();
  };

  const renderOptions = (opt: Options) => {
    return (
      <option key={opt.index} value={opt.value}>
        {opt.value.toUpperCase()}
      </option>
    );
  };
  
  const languages = ['en', 'pt', 'es'];

  return (
    <div>
      <Select value={selectedLanguage}
        onChange={(e) => changeLanguage(e.target.value)}
        children={languages.map((language: string, index: number) => (
          renderOptions({ value: language, index, array: languages })
        ))} />
    </div>
  );
}

export default LanguageSwitcher;
