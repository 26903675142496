import * as React from "react";
import rightImg from "images/about-hero-right-black.472x345.webp";
import SectionHero from "containers/PageAbout/SectionHero";
import { getTranslation } from "utils/translationUtils";

interface IAboutProps {
  className?: string;
}

const content: string[] = `${getTranslation("about.content")}`.split(";");

const About: React.FunctionComponent<IAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionAbout overflow-hidden relative ${className} `}
      data-nc-id="SectionAbout"
    >
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading={getTranslation("about.title")}
          btnText=""
          subHeading=""
          list={content}
        />
      </div>
    </div>
  );
};

export default About;
