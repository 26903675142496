import React, { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import scrollTo from "utils/scrollTo";

// <--- NavItemType --->
export interface MegamenuItem {
  id: string;
  image: string;
  title: string;
  items: NavItemType[];
}
export interface NavItemType {
  id: string;
  name: string;
  isNew?: boolean;
  href: string;
  targetBlank?: boolean;
  children?: NavItemType[];
  megaMenu?: MegamenuItem[];
  type?: "dropdown" | "megaMenu" | "none";
}

export interface NavigationItemProps {
  menuItem: NavItemType;
}

type NavigationItemWithRouterProps = RouteComponentProps & NavigationItemProps;

const NavigationItem: FC<NavigationItemWithRouterProps> = ({
  menuItem
}) => {

  // ===================== MENU MAIN MENU =====================
  const renderMainItem = (item: NavItemType) => {
    return (
      <span
        style={{cursor: "pointer"}}
        className="inline-flex items-center text-sm xl:text-base font-normal text-neutral-700 dark:text-neutral-300 py-2 px-4 xl:px-5 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"  
        onClick={() => scrollTo(item.href)}
      >
        {item.name}
      </span>
    );
  };

  return <li className="menu-item">{renderMainItem(menuItem)}</li>;
};
// Your component own properties

const NavigationItemWithRouter = withRouter<
  NavigationItemWithRouterProps,
  FC<NavigationItemWithRouterProps>
>(NavigationItem);
export default NavigationItemWithRouter;
