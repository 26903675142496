import React, { FC, useEffect, useState } from "react";
// import rightImgDemo from "images/BecomeAnAuthorImg.png";
import rightImgDemo from "images/dubai-360-logo-small.webp";
// import NcImage from "shared/NcImage/NcImage";
import { t } from "i18next";
import { getTranslation } from "utils/translationUtils";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const services: string[] = `${getTranslation("services.items")}`.split(";").map(service => ` ${service};`);

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  const mediaMatch = window.matchMedia('(min-width: 480px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
    mediaMatch.addEventListener('change', handler);
    return () => {
      mediaMatch.removeEventListener('change', handler);
    };
  }, []); // Empty dependency array to run the effect only once
  
  const styles = {
    bgImage: (isMobile: boolean) => ({
      background: "right 2% bottom 10%",
      backgroundImage: isMobile ? `url(${rightImg})` : "none",
      backgroundRepeat: "no-repeat",
    })
  };

  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="mb-0 w-full" style={styles.bgImage(matches)}>
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          {t("services.title")}
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          <span className="block max-w-[550px]">
            {t("services.desc")}
          </span>
          <ul className="block mt-6 text-neutral-500 dark:text-neutral-400">
            {services.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </span>
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
