import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import { getTranslation } from "utils/translationUtils";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "#",
    name: getTranslation("menu.home"),
  },
  {
    id: ncNanoId(),
    href: "SectionHowItWork",
    name: getTranslation("menu.how_it_work"),
  },
  {
    id: ncNanoId(),
    href: "SectionBecomeAnAuthor",
    name: getTranslation("menu.services"),
  },
  {
    id: ncNanoId(),
    href: "SectionOurFeatures",
    name: getTranslation("menu.bennefits"),
  },
  {
    id: ncNanoId(),
    href: "SectionAbout",
    name: getTranslation("menu.about"),
  },
  {
    id: ncNanoId(),
    href: "SectionContact",
    name: getTranslation("menu.contact"),
  },
  {
    id: ncNanoId(),
    href: "SectionClientSay",
    name: getTranslation("menu.testimonials"),
  }
];
