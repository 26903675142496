import React, { useEffect, useState } from "react";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";

interface IContactProps {
  className?: string;
}

interface ISecurityCheck {
  first: number;
  second: number;
  result: number;
}

const Contact: React.FC<IContactProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(`${t("contact.form.button.send")}`);
  const [disabled, setDisabled] = useState(false);
  const [securityCheck, setSecurityCheck] = useState<ISecurityCheck | null>();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let first: number = Math.floor(Math.random() * 10) + 1;
    let second: number = Math.floor(Math.random() * 10) + 1;

    setSecurityCheck({
      first: first,
      second: second,
      result: first + second
    });
  }, [])

  const sendEmail = async (e:any) => {
    e.preventDefault();

    setStatus(`${t("contact.form.button.sending")}`);

    const { math } = e.target.elements;

    if (parseInt(math.value) !== securityCheck?.result) {
      setVisible(true);
      setStatus(`${t("contact.form.button.send")}`);
      return;
    } else {
      setVisible(false);
    }

    const {
      REACT_APP_EMAILJS_SERVICEID,
      REACT_APP_EMAILJS_TEMPLATEID,
      REACT_APP_EMAILJS_USERID
    } = process.env;

    emailjs.sendForm(
      REACT_APP_EMAILJS_SERVICEID!, REACT_APP_EMAILJS_TEMPLATEID!,e.target, REACT_APP_EMAILJS_USERID)
      .then((result) => {
          console.log(result.text);
          if (result.text === "OK") {
            setDisabled(true);
            setStatus(`${t("contact.form.button.sent")}`);
          }
      }, (error) => {
          console.log(error.text);
          setStatus(`${t("contact.form.button.error")}`);
          setDisabled(true);
      });
    
  }
  
  return (
    <div
      className={`nc-SectionContact overflow-hidden ${className}`}
      data-nc-id="SectionContact"
    >
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("contact.title")}
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              <div>
                <h3 className="font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {t("contact.social.email")}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  <a href="mailto:dubailuxuryadvisor@gmail.com" rel="noreferrer" target="_blank">
                    dubailuxuryadvisor@gmail.com
                  </a>
                </span>
              </div>
              <div>
                <h3 className="font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {t("contact.social.whatsapp")}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  <a href="https://wa.me/971529379719?text=Hi%2C%20my%20name%20is%20%5BYour%20Name%5D%0AI%20saw%20your%20website%20and%20I%20would%20like%20to%20know%20more%20about%20..."
                    rel="noreferrer" target="_blank">+971 52 937 9719</a>
                </span>
              </div>
              <div>
                <h3 className="font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {t("contact.social.links")}
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={sendEmail}>
                <label className="block">
                  <Label>{t("contact.form.fullname.label")}</Label>

                  <Input
                    name="user_name"
                    placeholder={t("contact.form.fullname.placeholder")}
                    type="text"
                    className="mt-1"
                    required
                  />
                </label>
                <label className="block">
                  <Label>{t("contact.form.email.label")}</Label>

                  <Input
                    name="user_email"
                    type="email"
                    placeholder={t("contact.form.email.placeholder")}
                    className="mt-1"
                    required
                  />
                </label>
                <label className="block">
                <Label>{t("contact.form.phone.label")}</Label>

                  <Input
                    name="user_phone"
                    type="text"
                    placeholder={t("contact.form.phone.placeholder")}
                    className="mt-1"
                    required
                  />
                </label>
                <label className="block">
                  <Label>{t("contact.form.message.label")}</Label>

                  <Textarea name="message" required className="mt-1" rows={6} />
                </label>
                <label className="block">
                  <Label>
                    {t("contact.form.quiz.label")} {securityCheck?.first} + {securityCheck?.second}
                  </Label>

                  <Input name="math" type="text" className="mt-1" required />
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      display: `${visible ? "block" : "none"}`,
                    }}
                  >
                    {t("contact.form.quiz.security")}
                  </span>
                </label>
                <div>
                  <ButtonPrimary disabled={disabled} type="submit">
                    {status}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
