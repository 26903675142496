import SectionHero from "components/SectionHero/SectionHero";
import React, { useEffect } from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Contact from "components/Contact/Contact";
import About from "components/About/About";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import rightImgPng from "images/our-features-2.webp";
import { Helmet } from "react-helmet";
import SocialCardImage from "images/social-card-image.png"
import { useTranslation } from "react-i18next";

function PageHome() {
  const { t } = useTranslation();

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-yellow-grey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  const title: string = `${t("meta.title")}`;
  const url: string = "https://www.dubailuxuryadvisor.com/";
  const description: string = `${t("meta.desc")}`;

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* META TAGS */}
      <Helmet>
        {/* PRIMARY META TAGS */}
        <meta name="description" content={description} />

        {/* OPEN GRAPH / FACEBOOK */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={SocialCardImage} />

        {/* TWITTER */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={SocialCardImage} />

        {/* EXTRA META TAGS */}
        <meta name="keywords" content="Dubai, Luxury, Tourism" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="5 days days" />
        <meta name="author" content="Celso Jr" />
      </Helmet>

      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-24 pb-16" />

        {/* SECTION */}
        <SectionHowItWork />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

        {/* SECTION2 */}
        <SectionOurFeatures type="type2" rightImg={rightImgPng} />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <About />
        </div>

        {/* SECTION */}
        <div className="relative py-16">
          <Contact />
        </div>

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>
        
      </div>
    </div>
  );
}

export default PageHome;
