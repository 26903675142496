const scrollTo = (id: string) => {
    if (id === "#") {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    } else {
        // const element = document.getElementById(id) as HTMLDivElement;
        const element = document.querySelector(`[data-nc-id="${id}"]`) as HTMLDivElement;
        element.scrollIntoView({
            behavior: "smooth",
        });
    }
};

export default scrollTo;