import i18n from './i18n';

export function getTranslation(key: string, language?: string): string {
  const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';

  // avoid infinity loop
  if (selectedLanguage !== i18n.language) {
    i18n.changeLanguage(selectedLanguage);
  }

  return i18n.t(key);
}
